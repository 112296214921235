import { useMsal } from '@azure/msal-react'
import { EventMessage, EventType, InteractionType, } from '@azure/msal-browser'
import { useCallback, useEffect, useRef } from 'react'
import { setupApiClient } from '@/bootstrap/axios'
import store from '@/bootstrap/redux'
import { getIsCAEmployee, getIsImpersonating } from '../redux/authSelectors'
import { EUserCan } from '../types'
import { routes } from '@/bootstrap/router'
import { history } from '@/App'
import { getPermissions } from '../helpers'
import { PathState } from '@/shared/helpers/matchRouteState'
import { AuthenticationResultWithClaims } from '@/shared/types/Claims'

export interface UseAuthenticationProps {
  skipRedirect: boolean,
}

export default function useAuthentication({ skipRedirect }: UseAuthenticationProps) {
  const { instance } = useMsal()
  const apiInitialized = useRef(false)

  const handleMSALEvent = useCallback((message: Omit<EventMessage, 'payload'> & { payload: AuthenticationResultWithClaims }) => {
    const { payload, eventType, interactionType } = message
    const isRedirect = interactionType === InteractionType.Redirect && ([EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_SUCCESS].includes(eventType))
    
    // skips MSAL event callback in case of no redirect event 
    if (!isRedirect) {
      return
    }

    // adds account information and claims to redux
    store.dispatch({
      type: 'auth/loginSuccess',
      payload,
    })

    // skips redirect event
    if (skipRedirect) {
      return
    }

    // skips redirect event if no payload token
    if (!payload?.idToken) {
      return
    } 

    const claims = payload?.account?.idTokenClaims
    const stateData = JSON.parse(payload?.state || '{}') as PathState
    const isImpersonating = getIsImpersonating(claims)
    const isCAEmployee = getIsCAEmployee(claims, isImpersonating)
    const permissions = getPermissions(claims, isCAEmployee, isImpersonating)

    // path was saved in MSAL state
    if (payload?.state && 'pathKey' in stateData) {
      const { pathKey, query, ...segments } = stateData
      const { path, preserveQuery } = routes?.[stateData.pathKey] ?? {}
      if (path) {
        const formattedPath = Object.entries(segments).reduce((_path, [key, val]) => {
          if (path.includes(key)) {
            return _path.replace(`:${key}`, val)
          }
          return _path
        }, path)
        history.replace({ pathname: formattedPath, search: preserveQuery ? query : undefined })
        return
      }
    }

    const route = Object.values(routes).find((_route) => _route.path === window.location.pathname)

    if (route && route?.preserveQuery) {
      const { query } = stateData
      history.replace({ pathname: route.path, search: query })
      return
    }

    if (window.location.pathname === '/' && isCAEmployee && permissions.includes(EUserCan.Access_Clients)) {
      history.replace(routes.clients.path)
    }
  }, [skipRedirect])

  useEffect(() => {
    // initilize API client with access to MSAL instance
    if (!apiInitialized.current) {
      setupApiClient(instance)
      apiInitialized.current = true
      instance.enableAccountStorageEvents()
      instance.addEventCallback(handleMSALEvent)
    }
  }, [handleMSALEvent, instance, skipRedirect])
}

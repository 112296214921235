import store, { RootState } from "@/bootstrap/redux"
import { mainBoxServices } from "./boxServices"
import { QueryStatus } from "@reduxjs/toolkit/dist/query"
import { CombinedState, QueryState } from "@reduxjs/toolkit/dist/query/core/apiState"
import { DEPLOYED_ENV } from "@/environment/environment.constants"

const logMessageToConsole = (message: string, ...optionalParams: any[]) => {
  if (DEPLOYED_ENV !== 'prod') {
    if (optionalParams) {
      console.log(message, optionalParams)
    } else {
      console.log(message)
    }
  }
}

const invalidateBoxServiceQueries = (timeoutDelay = 0) => {
  let state: RootState = store.getState() as RootState
  let api: CombinedState<any, any, "api"> = state.api
  let apiQueries: QueryState<any> = api.queries
  let apiKeys: string[] = Object.keys(apiQueries)

  const resetAPIState = () => {
    try {
      logMessageToConsole('Started to reset Box API state')
      store.dispatch(mainBoxServices.util.resetApiState())
      logMessageToConsole('Completed to reset Box API state')
    } catch(error) {
      logMessageToConsole('Falied to reset Box API state')
    }
  }

  // separate method for Box Services
  const invalidateBoxTokenQuery = () => {
    try {
      logMessageToConsole('Started to invalidate Box Token cache')

      // Box Token API
      const boxTokenKey = apiKeys.find(k => k.includes('BoxToken'))
      const boxTokenQuery = boxTokenKey && apiQueries[boxTokenKey]

      if (boxTokenQuery) {
        const endpointArgs = boxTokenQuery.originalArgs as any
        const endpointStatus = boxTokenQuery.status

        if (endpointStatus === QueryStatus.uninitialized) {
          logMessageToConsole('Unitialized Box Token')
          setTimeout(() => {
            store.dispatch(mainBoxServices.util.invalidateTags([{ type: 'BoxToken' }]));
            // store.dispatch(mainBoxServices.endpoints.getBoxToken.initiate(endpointArgs))
          }, timeoutDelay)
        } else {
          store.dispatch(mainBoxServices.util.invalidateTags([{ type: 'BoxToken' }]));
          // store.dispatch(mainBoxServices.endpoints.getBoxToken.initiate(endpointArgs))
        }

        logMessageToConsole('Completed to invalidate Box Token cache')
      }
    } catch (error) {
      console.error('Failed to invalidate Box Token cache', error)
    }
  }

  const invalidateBoxRecentFilesQuery = () => {
    try {
      logMessageToConsole('Started to invalidate Box Recent Files cache')

      // Box Recent Files API
      const boxRecentFilesKey = apiKeys.find(k => k.includes('BoxRecentFiles'))
      const boxRecentFilesQuery = boxRecentFilesKey && apiQueries[boxRecentFilesKey]

      if (boxRecentFilesQuery) {
        const endpointArgs = boxRecentFilesQuery.originalArgs as any
        const endpointStatus = boxRecentFilesQuery.status

        if (endpointStatus === QueryStatus.uninitialized) {
          logMessageToConsole('Unitialized Box Recent Files')
          setTimeout(() => {
            store.dispatch(mainBoxServices.util.invalidateTags([{ type: 'BoxRecentFiles' }]));
            // store.dispatch(mainBoxServices.endpoints.getBoxToken.initiate(endpointArgs))
          }, timeoutDelay)
        } else {
          store.dispatch(mainBoxServices.util.invalidateTags([{ type: 'BoxRecentFiles' }]));
          // store.dispatch(mainBoxServices.endpoints.getBoxToken.initiate(endpointArgs))
        }

        logMessageToConsole('Completed to invalidate Box Recent Files cache')
      }
    } catch (error) {
      console.error('Failed to invalidate Box Recent Files cache', error)
    }
  }

  try {
    // initializes API settings
    state = store.getState() as RootState
    api = state.api
    apiQueries = api.queries
    apiKeys = Object.keys(apiQueries)
    
    // invalidates Box Services queries
    resetAPIState()
    invalidateBoxTokenQuery()
    invalidateBoxRecentFilesQuery()
  } catch (error) {
    console.error('Error while invalidating cache for Box Services.', error)
  }
}

export const invalidateBoxServices = (timeoutDelay = 0) => {
  logMessageToConsole('Started to invalidate box services with timeout delay:', timeoutDelay)

  if (!timeoutDelay) {
    invalidateBoxServiceQueries(timeoutDelay)
  } else {
    setTimeout(invalidateBoxServiceQueries, timeoutDelay)
  }
}
